import React from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import SEO from "../components/SEO/index"
import { Section } from "../components/CompoundComponents/Section"
import { Columns, Column } from "../components/CompoundComponents/Columns"
import Schedule from "../components/Schedule"
import NavBar from "../components/navbar"

import "./mystyles.scss"

const CardiologyPage = ({ intl, location, data }) => {
  return (
    <>
      <SEO title="Fanomed clinics" description="Fanomed clinics" />
      <NavBar />
      <Section>
        <Columns>
          <Column is4>
            <div className="box">
              <Img
                fluid={
                  data.doctors.edges[3].node.featuredImage.childImageSharp.fluid
                }
                alt={data.doctors.edges[3].node.id}
              />
            </div>
            <Schedule />
          </Column>
          <Column>
            <div className="content">
              <h3 className="is-size-5-mobile">
                {intl.formatMessage({
                  id: `doctors.item-3.name`,
                })}{" "}
                <br />{" "}
                <span className="has-text-weight-bold is-size-6 has-family-nunito">
                  {" "}
                  {intl.formatMessage({
                    id: `doctors.item-3.position`,
                  })}
                </span>
              </h3>
              <h3 className="is-size-5-mobile">
                {intl.formatMessage({
                  id: `doctors.item-3.education.title-1`,
                })}
              </h3>
              <p className="has-family-nunito">
                {intl.formatMessage({
                  id: `doctors.item-3.education.desc-1`,
                })}
              </p>
              <h3 className="is-size-5-mobile">
                {intl.formatMessage({
                  id: `doctors.item-3.experience.title-1`,
                })}
              </h3>
              <p className="has-family-nunito">
                {intl.formatMessage({
                  id: `doctors.item-3.experience.desc-1`,
                })}
              </p>
            </div>
          </Column>
        </Columns>
      </Section>
    </>
  )
}

export const CardiologyPageQuery = graphql`
  query {
    doctors: allDoctorsYaml {
      edges {
        node {
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 700) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

export default injectIntl(CardiologyPage)
